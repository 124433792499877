import React, { useEffect, useState } from 'react';
import AuthRouter from './AuthRouter';
import App from '@/app';
import role from '@/config/roleConfig';
import { useHistory, useLocation } from 'react-router-dom';
import useNetwork from '@/hooks/useNetwork';
import { useSelector } from 'react-redux';
import { selectAuth } from '@/redux/auth/selectors';
import { Spin, notification } from 'antd';
import { storePersist } from '@/redux/storePersist';
import { getBrowser } from '@/utils/getBrowser';

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const waitTime = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export default function Router() {
  const [value, setValue] = useState(false);
  const debouncedRefresh = useDebouncedValue(value, 1000);
  const [isOnline] = useNetwork();
  const { current } = useSelector(selectAuth);
  const history = useHistory();
  const { search, pathname } = useLocation();

  const auth = storePersist.get('auth');

  const browser = getBrowser();

  const HandleRefresh = async () => {
    await waitTime(500);
    setValue(true);
  };

  window.addEventListener('load', () => {
    if (browser !== 'Safari') {
      setValue(true);
    } else {
      HandleRefresh();
    }
  });

  window.sessionStorage.setItem('lastUrl', pathname + search);

  if (!isOnline) {
    notification.config({
      duration: 0,
    });
    notification.error({
      message: 'No internet connection',
      description: 'Cannot connect to the server, Check your internet network',
    });
  }

  useEffect(() => {
    if (current && current?.isLoggedIn && current?.role) {
      if (pathname === '/login' || pathname === '/') {
        window.location.replace(`/${role[`${current?.role}`][`side_menu`][0].toLowerCase()}`);
      }
    }
    return () => {};
  }, [current]);

  if (current?.isLoggedIn && auth?.isLoggedIn) {
    let lastUrl = window.sessionStorage.getItem('lastUrl');
    let condition = auth.current.access_token === current.access_token;

    if (!condition) {
      if (lastUrl !== '/login' || lastUrl !== '/notfound' || lastUrl !== '/') {
        window.location.replace(`/${role[`${auth.current.role}`][`side_menu`][0].toLowerCase()}`);
      }
    }

    if (value) {
      return <App />;
    } else {
      return (
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: 'transparent',
            display: 'flex',
          }}
        >
          <Spin style={{ margin: 'auto' }} />
        </div>
      );
    }
  } else {
    if (pathname !== '/' && pathname !== '/login') {
      history.push('/login');
    }

    return <AuthRouter />;
  }
}
