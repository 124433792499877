export const routesConfig = [
  {
    path: '/dashboard',
    component: 'Dashboard',
  },
  {
    path: '/customer',
    component: 'Customer/index',
  },
  {
    path: '/customer/create',
    component: 'Customer/CustomerCreate',
  },
  {
    path: '/customer/read/:id',
    component: 'Customer/CustomerRead',
  },
  {
    path: '/customer/update/:id',
    component: 'Customer/CustomerUpdate',
  },
  {
    path: '/inventory',
    component: 'Inventory/index',
  },
  {
    path: '/inventory/create',
    component: 'Inventory/InventoryCreate',
  },
  {
    path: '/inventory/edit/:id',
    component: 'Inventory/InventoryUpdate',
  },
  {
    path: '/inventory/category',
    component: 'Inventory/inventoryCategory',
  },
  {
    path: '/inventory/read/:id',
    component: 'Inventory/InventoryRead',
  },
  {
    path: '/inventory/update/:id',
    component: 'Inventory/InventoryUpdate',
  },
  {
    path: '/inventory/pay/:id',
    component: 'Inventory/InventoryRecordPayment',
  },
  {
    path: '/saleOrder',
    component: 'Quote/index',
  },
  {
    path: '/saleOrder/create',
    component: 'Quote/QuoteCreate',
  },
  {
    path: '/saleOrder/read/:id',
    component: 'Quote/QuoteRead',
  },
  {
    path: '/saleOrder/update/:id',
    component: 'Quote/QuoteUpdate',
  },
  // {
  //   path: '/employee',
  //   component: 'Employee',
  // },
  {
    path: '/employee',
    component: 'Employee/index',
  },
  {
    path: '/employee/create',
    component: 'Employee/EmployeeCreate',
  },
  {
    path: '/employee/department',
    component: 'Employee/DepartmentCategory',
  },
  {
    path: '/employee/read/:id',
    component: 'Employee/EmployeeRead',
  },
  {
    path: '/employee/update/:id',
    component: 'Employee/EmployeeUpdate',
  },
  {
    path: '/sales',
    component: 'Sales/index',
  },
  {
    path: '/sales/read/:id',
    component: 'Sales/SalesRead',
  },
  {
    path: '/sales/create',
    component: 'Sales/SalesCreate',
  },
  {
    path: '/sales/update/:id',
    component: 'Sales/SalesUpdate',
  },
  {
    path: '/payment/inventory',
    component: 'PaymentInventory/index',
  },
  {
    path: '/payment/inventory/create',
    component: 'PaymentInventory/PaymentInventoryCreate',
  },
  {
    path: '/payment/inventory/read/:id',
    component: 'PaymentInventory/PaymentInventoryRead',
  },
  {
    path: '/payment/inventory/update/:id',
    component: 'PaymentInventory/PaymentInventoryUpdate',
  },
  // {
  //   path: '/employee',
  //   component: 'Employee',
  // },
  {
    path: '/user',
    component: 'Admin/index.jsx',
  },
  {
    path: '/user/create',
    component: 'Admin/adminCreate.jsx',
  },
  {
    path: '/user/update/:id',
    component: 'Admin/adminUpdate.jsx',
  },
  {
    path: '/user/read/:id',
    component: 'Admin/adminRead.jsx',
  },
  {
    path: '/user/update-password/:id',
    component: 'Admin/updatePassword.jsx',
  },
  {
    path: '/settings',
    component: 'Settings/Settings',
  },
  {
    path: '/payment/mode',
    component: 'PaymentMode',
  },
  {
    path: '/email',
    component: 'Email/index',
  },
  {
    path: '/email/read/:id',
    component: 'Email/EmailRead',
  },
  {
    path: '/email/update/:id',
    component: 'Email/EmailUpdate',
  },
  {
    path: '/settings/advanced',
    component: 'AdvancedSettings',
  },
  {
    path: '/profile',
    component: 'Profile',
  },
  {
    path: '/lead',
    component: 'Lead/index',
  },
  {
    path: '/lead/create',
    component: 'Lead/LeadCreate',
  },
  {
    path: '/lead/read/:id',
    component: 'Lead/LeadRead',
  },
  {
    path: '/lead/update/:id',
    component: 'Lead/LeadUpdate',
  },
  {
    path: '/offer',
    component: 'Offer/index',
  },
  {
    path: '/offer/create',
    component: 'Offer/OfferCreate',
  },
  {
    path: '/offer/read/:id',
    component: 'Offer/OfferRead',
  },
  {
    path: '/offer/update/:id',
    component: 'Offer/OfferUpdate',
  },
];
