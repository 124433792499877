import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Menu, Dropdown,Badge } from 'antd';
import Notifications from '@/components/Notification';

import {
  AppstoreOutlined,
  SettingOutlined,
  MailOutlined,
  LogoutOutlined,
  BellOutlined,
  UserOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { selectAuth,selectCurrentUser } from '@/redux/auth/selectors';
import history from '@/utils/history';
import uniqueId from '@/utils/uinqueId';

import { BASE_URL } from '@/config/serverApiConfig';

import { login ,logout} from '@/redux/auth/actions';


// const { loading: isLoading } = useSelector(selectAuth);

export default function HeaderContent() {

  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const profileDropdown = (
    <div className="profileDropdown whiteBox shadow" style={{ minWidth: '200px' }}>
      <div className="pad15" onClick={(e)=>{}}>
      {/* <div style={{
              width: '60px',
              height: '60px',
              border: '2px solid #1B98F5',
              display:'flex',
              borderRadius:'50%'
            }}>
              <img src='/user.png' style={{width:'50px',height:'50px',margin:'auto'}} /> 
      </div> */}
        <Avatar
          size="large"
          className="last"
          src={`${BASE_URL}${currentUser?.photo}`}
          style={{ float: 'left' }}
        />
        <div className="info">
          <p className="strong">
            {currentUser?.name} {currentUser?.surname}
          </p>
          <p>{currentUser?.email}</p>
        </div>
      </div>
      <div className="line"></div>

      <div>
        <Menu>
          {
            currentUser.role === 'super admin' ? null :
            
            <Menu.Item
            icon={<SettingOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/profile')}
          >
            Profile Settings
          </Menu.Item>
          }
  
          {
            ( currentUser.role === 'admin' || currentUser.role === 'super admin') && (
            <Menu.Item
              id="settings"
              icon={<SettingOutlined />}
              key={`${uniqueId()}`}
              // onClick={() => history.push('/settings/')}
            >
              App Settings
            </Menu.Item>
            )
          }

        </Menu>

      </div>
      <div className="line"></div>
      <div>
        <Menu>
          <Menu.Item
            icon={<LogoutOutlined />}
            key={`${uniqueId()}`}
            onClick={() => {  dispatch(logout()); }}
          >
            logout
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <div className="headerIcon" style={{ position: 'absolute', right: 0, zIndex: '99' }}>

      <Dropdown overlay={profileDropdown} trigger={['click']} placement="bottomRight">
        {/* <Badge dot> */}
        {/* <Avatar className="last" src={`${BASE_URL}${currentUser?.photo}`} /> */}
        <Avatar className="last" icon={<UserOutlined />} />
        {/* </Badge> */}
      </Dropdown>

      {/* <Avatar icon={<AppstoreOutlined />} /> */}

      <Dropdown overlay={<Notifications />} trigger={['click']} placement="bottomRight">
        {/* <Badge dot> */}
        <Avatar icon={<BellOutlined />} />
        {/* </Badge> */}
      </Dropdown>

    </div>
  );
}
