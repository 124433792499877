import React, { lazy, useEffect } from 'react';
import AppRouter from '@/router/AppRouter';
import AuthRouter from '@/router/AuthRouter';
import { Redirect, Route } from 'react-router-dom';
import useNetwork from '@/hooks/useNetwork';

import { Layout, notification } from 'antd';

import Navigation from '@/app/Navigation';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@/redux/auth/selectors';

import HeaderContent from '@/app/HeaderContent';
import { useNetworkState } from 'react-use';

function App() {
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      // Change viewport meta tag to set the viewport width to match a desktop view
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 'width=1024');
      }
    }
  }, []);

  const networkState = useNetworkState();

  const user = useSelector(selectCurrentUser);

  if (user === undefined) {
    return <AuthRouter />;
  }

  if (user?.isLoggedIn) {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Navigation />
        <Layout style={{ minHeight: '100vh' }}>
          <HeaderContent />
          <AppRouter />
        </Layout>
      </Layout>
    );
  }
}

export default App;
