export default {
    "super admin":{
        "side_menu":['dashboard','inventory/category','customer','saleOrder','sales','employee','user','settings','profile'],
        "customer":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
        },
        "inventory":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
            "category":{
                "read":true,
                "create":true,
                "edit":true,
                "delete":true,
            }
        },
        "sales":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
        },
        "saleOrder":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
            "convert2sale":true
        },
        "employee":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
        }
    },
    "admin":{
        "side_menu":['dashboard','inventory/category','customer','saleOrder','sales','employee','user','settings','profile'],
        "customer":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
        },
        "inventory":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
            "category":{
                "read":true,
                "create":true,
                "edit":true,
                "delete":true,
            }
        },
        "sales":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
        },
        "saleOrder":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
            "convert2sale":true
        },
        "employee":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":true,
        }
    },
    "staff":{
        "side_menu":['dashboard','inventory/category','customer','saleOrder','sales','profile'],
        "customer":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":false,
        },
        "inventory":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":false,
            "category":{
                "read":true,
                "create":true,
                "edit":true,
                "delete":true,
            }
        },
        "sales":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":false,
        },
        "saleOrder":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":false,
            "convert2sale":true
        },
        "employee":{
            "read":true,
            "create":false,
            "edit":false,
            "delete":false,
        }
    },
    "executive":{
        "side_menu":['inventory/category','customer','saleOrder','sales','profile'],
        "customer":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":false,
        },
        "inventory":{
            "read":true,
            "create":false,
            "edit":false,
            "delete":false,
            "category":{
                "read":true,
                "create":false,
                "edit":false,
                "delete":false,
            }
        },
        "sales":{
            "read":true,
            "create":false,
            "edit":false,
            "delete":false,
        },
        "saleOrder":{
            "read":true,
            "create":true,
            "edit":true,
            "delete":false,
            "convert2sale":false
        },
        "employee":{
            "read":false,
            "create":false,
            "edit":false,
            "delete":false,
        }
    }
  };