import React, { useLayoutEffect, lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { settingsAction } from '@/redux/settings/actions';
import { routesConfig } from './RoutesConfig';
import role from '@/config/roleConfig';
import { selectCurrentUser } from '@/redux/auth/selectors';

const Login = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/pages/Login'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));

const SubMenuRouter = ({ subMenuRouter }) => {
  subMenuRouter.map((subMenu) => {
    return (
      <PrivateRoute
        key={subMenu.component}
        path={subMenu.path}
        exact={subMenu.exact || true}
        component={lazy(() =>
          import(/* webpackChunkName: "[request]" */ `@/pages/${subMenu.component}`)
        )}
      />
    );
  });
};

export default function AppRouter() {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  useLayoutEffect(() => {
    dispatch(settingsAction.list({ entity: 'setting' }));
  }, []);

  const location = useLocation();

  let routes = [];

  if (user?.isLoggedIn && user?.role) {
    routes = role[user?.role]['side_menu'].map((element) => {
      return element.replaceAll('', '');
    });
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          {routesConfig.map((routeItem) => {
            let entity = routeItem.path.split('/')[1];

            return routes.map((route) => {
              route = route.split('/')[0];

              if (route == entity) {
                return (
                  <PrivateRoute
                    key={routeItem.component}
                    path={routeItem.path}
                    exact={routeItem.exact || true}
                    component={lazy(() => import(`@/pages/${routeItem.component}`))}
                  />
                );
              }
            });
          })}
          <PublicRoute path="/" component={Login} render={() => <Redirect to="/login" />} exact />
          <PublicRoute component={Login} path="/login" exact />
          <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
