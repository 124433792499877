import * as actionTypes from './types';
import * as authService from '@/auth';
import axios from 'axios'

import history from '@/utils/history';

export const login =
  ({ loginData }) =>

  async (dispatch) => {

    dispatch({
      type: actionTypes.LOADING_REQUEST,
      payload: { loading: true },
    });

    const data = await authService.login({ loginData });

    if (data.success === true) {
      
      window.localStorage.setItem('isLoggedIn', true);
      window.localStorage.setItem('auth', JSON.stringify({
        current: data.result,
        loading: false,
        isLoggedIn: true,
      }));

      window.localStorage.setItem('access_token', JSON.stringify(data.result?.access_token))

      setTimeout(
        dispatch({
          type: actionTypes.LOGIN_SUCCESS,
          payload: data.result,
        })
        , 2000);

    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        payload: data,
      });
    }
  };

export const logout = () => async (dispatch) => {
  window.localStorage.clear();
  var Cookies = document.cookie.split(';');
 // set past expiry to all cookies
  for (var i = 0; i < Cookies.length; i++) {
    document.cookie = Cookies[i] + "=; expires="+ new Date(0).toUTCString();
  }
  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
    payload: null,
  });
  history.push('/login');
};
