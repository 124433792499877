import axios from 'axios';
import { API_BASE_URL } from '@/config/serverApiConfig';

import errorHandler from './errorHandler';
import successHandler from './successHandler';

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('access_token'))}`;

const request = {

  create: async ({ entity, jsonData }) => {
    try {
      const response = await axios.post(entity + '/create', jsonData);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  read: async ({ entity, id }) => {
    try {
      const response = await axios.get(entity + '/read/' + id);
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  update: async ({ entity, id, jsonData }) => {
    try {
      const response = await axios.patch(entity + '/update/' + id, jsonData);

      if (entity != 'inventoryCategory') {
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true,
        });
      }

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  InventoryUpdate: async (entity, id, jsonData) => {
    try {
      const formData = new FormData();

      // Append the file to the formData object

      jsonData.imageFiles.map((item) => {
        formData.append(`${item.uid}.${item.type.split('/')[1]}`, item);
      });

      formData.append('data', JSON.stringify(jsonData.inventoryData));

      // Post formData to the backend
      const response = await axios.patch(entity + '/update/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  InventoryCreate: async (entity, jsonData) => {
    try {
      const formData = new FormData();

      // Append the file to the formData object
      jsonData.imageFiles.map((item, index) => {
        formData.append(`${jsonData.inventoryData.previewImage[index]}`, item);
      });
      formData.append('data', JSON.stringify(jsonData.inventoryData));

      // Post formData to the backend
      const response = await axios.post(`${entity}/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  upload: async (entity, file) => {
    try {
      // Create a new instance of FormData
      const formData = new FormData();

      // Append the file to the formData object
      formData.append('excelFile', file);

      // Post formData to the backend
      const response = await axios.post(`${entity}/update-from-excel`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  delete: async ({ entity, id, options = {} }) => {
    try {
      const response = await axios.delete(entity + '/delete/' + id);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  // filter: async ({ entity, options = {} }) => {
  //   try {
  //     let filter = options.filter ? 'filter=' + options.filter : '';
  //     let equal = options.equal ? '&equal=' + options.equal : '';
  //     let query = `?${filter}${equal}`;

  //     const response = await axios.get(entity + '/filter' + query);
  //     successHandler(response, {
  //       notifyOnSuccess: false,
  //       notifyOnFailed: false,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return errorHandler(error);
  //   }
  // },

  search: async ({ entity, options = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }

      query = query.slice(0, -1);

      const response = await axios.get(entity + '/search' + query);

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  salesBasedEmployees: async ({ entity, options = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }

      query = query.slice(0, -1);

      // headersInstance.cancelToken = source.token;

      const response = await axios.get('employee' + '/search' + query);

      const dataToSend = response.data;

      const resultfiltered = response.data.result.filter((item) => {
        return item.department == 'Sales';
      });

      dataToSend['result'] = resultfiltered;

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return dataToSend;
    } catch (error) {
      return errorHandler(error);
    }
  },

  saleOrderBasedOnCustomer: async ({ entity, options = {}, customer }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }

      query = query.slice(0, -1);

      // headersInstance.cancelToken = source.token;

      const response = await axios.get('saleOrder' + '/search' + query);
      // const dataToSend = response.data;

      // Filter based on customer ID and status 'pending'
      const resultFiltered = response?.data?.result.filter((item) => {
        return item?.client?._id == customer && item.status === 'draft';
      });

      // dataToSend['result'] = resultFiltered;
      response.data.result = resultFiltered;

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  locationFetching: async ({ entity, options = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }

      query = query.slice(0, -1);

      const response = await axios.get(entity + '/search' + query);

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  filter: async ({ entity, options = {}, filter = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }

      query = query.slice(0, -1);

      const response = await axios.post(entity + '/filter' + query, filter);

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  list: async ({ entity, options = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }
      query = query.slice(0, -1);

      const response = await axios.get(entity + '/list' + query);

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  listAll: async ({ entity }) => {
    try {
      const response = await axios.get(entity + '/listAll');

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  post: async ({ entity, jsonData, options = {} }) => {
    try {
      const response = await axios.post(entity, jsonData);

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  get: async ({ entity }) => {
    try {
      const response = await axios.get(entity);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  patch: async ({ entity, jsonData }) => {
    try {
      const response = await axios.patch(entity, jsonData);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  source: () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source;
  },

  summary: async ({ entity, currentMonth, currentYear }) => {
    try {
      if (entity === 'inventory') return null;

      if (entity === 'ActiveCustomer') {
        let apiUrl = `${entity}/summary`;

        // Check if currentMonth and currentYear are provided and add them to the URL as query parameters
        if (currentMonth !== undefined) {
          apiUrl += `?month=${currentMonth}`;
        }

        if (currentYear !== undefined) {
          apiUrl += `&year=${currentYear}`;
        }

        const response = await axios.get(apiUrl);

        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: false,
        });

        return response.data;
      } else {
        const response = await axios.get(entity + '/summary');

        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: false,
        });

        return response.data;
      }
    } catch (error) {
      return errorHandler(error);
    }
  },

  mail: async ({ entity, jsonData }) => {
    try {
      const response = await axios.post(entity + '/mail/', jsonData);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  convert: async ({ entity, id }) => {
    try {
      const response = await axios.get(`${entity}/convert/${id}`);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
};
export default request;
