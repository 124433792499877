import { notification } from 'antd';

import codeMessage from './codeMessage';

const successHandler = (response, options = { notifyOnSuccess: false, notifyOnFailed: true }) => {

  const { data } = response;

  if (data && data.success === true) {
    const message = response.data && data.message;
    const successText = message || codeMessage[response.status];

    if (options.notifyOnSuccess) {
      notification.config({
        duration: 5,
      });
      notification.success({
        message: `Success`,
        description: successText,
      });
    }
  } else {

    if(response.status !== 401){

      const message = response.data && data.message;
      const errorText = message || codeMessage[response.status];
      const { status } = response;

      if (options.notifyOnFailed) {
        notification.config({
          duration: 5,
        });
        notification.error({
          message: `Error`,
          description: errorText,
        });
      }

    }

  }
};

export default successHandler;
